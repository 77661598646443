.section {
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.pricing {
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 40px;
}

.pricing-plan {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 280px;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

    .pricing-plan:hover {
        transform: translateY(-10px);
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
    }

    .pricing-plan h3 {
        font-size: 24px;
        color: #ff6e7f;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .pricing-plan p {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin-bottom: 20px;
    }

    .pricing-plan ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

        .pricing-plan ul li {
            font-size: 16px;
            color: #666;
            padding: 10px 0;
            border-bottom: 1px solid #ddd;
        }

            .pricing-plan ul li:last-child {
                border-bottom: none;
            }

    .pricing-plan:last-child p {
        color: #555;
        font-style: italic;
        font-size: 18px;
    }


    .pricing-plan button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #ff6e7f;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

        .pricing-plan button:hover {
            background-color: #ff4e5f;
        }
