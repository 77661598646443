body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.App-header {
    background-color: #282c34;
    padding: 20px;
    color: white;
    text-align: center;
}

    .App-header nav a {
        margin: 0 15px;
        color: white;
        text-decoration: none;
        font-weight: bold;
    }

.section {
    padding: 60px 20px;
    text-align: center;
}

.pricing {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}

.pricing-plan {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 5px;
    width: 30%;
}

    .pricing-plan h3 {
        margin-top: 0;
    }

footer.App-footer {
    background-color: #282c34;
    padding: 20px;
    color: white;
    text-align: center;
}
